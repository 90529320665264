.overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(46, 2, 15, 0.871);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  margin: 20px;
  font-family: 'Play', sans-serif;
  position: relative;
  background-color: rgb(221, 27, 66);
  box-shadow: rgb(255, 0, 98) 0 5px 30px, rgba(255, 0, 38, 0.861) 0 7px 13px -3px, #ffffffdf 0 -3px 0 inset;
  color: #FFF;
  opacity: 80%;
  width: 450px;
  height: 260px;
  gap: 20px;
  border-radius: 10px;
}

.modal h1 {
  font-size: 2rem;
  font-family: 'Play', sans-serif;

}

.gover{
  padding-top: 30px;
  font-family: 'Play', sans-serif;

}

.game-over-message{
  padding: 1rem;
  margin-bottom: 20px;
}

.final-score {
  font-size: 30px;
  width: 100%;
  padding: 2rem 0;
  text-transform: capitalize;
}

.close-modal {


  margin: 0;
  background: transparent;
  padding-top: 20px;
  border: none;
}

/* button styling */
.close-modal  {
  grid-area: closeMDL;
  scale: 35%;
  --border-right: 6px;
  --text-stroke-color: rgb(255, 251, 251);
  --animation-color: #1bff7a;
  --fs-size: 2em;
  letter-spacing: 3px;
  text-decoration: none;
  font-size: var(--fs-size);
  padding-top: 20px;

  position: relative;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px var(--text-stroke-color);
}
/* this is the text, when you hover on button */
.hover-text {
  position: absolute;
  box-sizing: border-box;
  content: attr(data-text);
  color: var(--animation-color);
  width: 0%;
  inset: 0;
  padding-top: 20px;
  border-right: var(--border-right) solid var(--animation-color);
  overflow: hidden;
  transition: 0.5s;
  -webkit-text-stroke: 1px var(--animation-color);
}
/* hover */
.button:hover .hover-text {
  width: 100%;
  filter: drop-shadow(0 0 23px var(--animation-color))

}





.message {
  margin-top: 20px;
}